import React from 'react'
import "../assets/css/highlightsheader.css"
import rapidhightlightlogo from "../assets/images/Rapid Highlight.svg"
import hamnurgurmenu from "../assets/images/hamburger.png"
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
const HighLightsHeader = () => {
    const [scrolled, setScrolled] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 50) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
      
      const goToHomePage = () => {
        navigate("/");
      };
    
  return (
    <div className={`container custom-header-container ${scrolled ? "scrolled" : ""}`}>
      <div  className={`highlight-header-container ${scrolled ? "scrolled" : ""}`}>

        <div onClick={goToHomePage} className={`rapid-hight-light-logo-wrapper ${scrolled ? "scrolled" : ""}`}>
            <img src={rapidhightlightlogo} alt="" />
        </div>
        {/* <div className={`hamburger-menu-hl-wrapper ${scrolled ? "scrolled" : ""}`}>
            <img src={hamnurgurmenu} alt="" />
        </div> */}
      </div>
    </div>
  )
}

export default HighLightsHeader